
import { defineComponent, inject, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { setTitle } from "../router";
import { Database } from "vuebase";
import { Artist, CartItem, Product } from "../main";
import marked from "marked";
import pluralize from "pluralize";
import OrderController from "@/controllers/order-controller";

export default defineComponent({
    name: "Product",
    setup() {
        const productId = useRoute().params.id as string;
        const database = inject(Database.InjectionKey);
        const product = database?.collection<Product>("products").document(productId);
        const artist = ref<Database.Document<Artist>>();
        const print = ref<Partial<CartItem>>({ productId: productId, selections: {}, quantity: 1 });
        const orderController = new OrderController();
        const router = useRouter();

        watch(
            () => product,
            () => {
                if (product?.data?.category == "Custom Print") {
                    setTitle(pluralize(product?.data?.name ?? ""));
                } else if (product?.data?.category == "Gallery Print") {
                    setTitle(product?.data?.name ?? "");
                }

                if (product?.data?.artist && artist.value == undefined) {
                    artist.value = database?.collection<Artist>("artists").document(product?.data?.artist);
                }
            },
            { immediate: true, deep: true },
        );

        watch(
            print,
            () => {
                print.value.price = orderController.calculateItemPrice(print.value);
            },
            { deep: true, immediate: true },
        );

        function addToCart() {
            orderController.addItem(print.value as CartItem);
            router.push("/cart");
        }

        return { database, print, product, marked, pluralize, artist, addToCart };
    },
});
