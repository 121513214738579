<template>
    <c-panel>
        <c-grid v-if="product && product.data">
            <c-grid-item class="width-50-all width-100-sm">
                <c-image-slider :images="product.data.images" />
            </c-grid-item>
            <c-grid-item class="width-50-all width-100-sm" v-if="product.data.category == 'Custom Print'">
                <h1>{{ pluralize(product.data.name) }}</h1>
                <p class="mb-3" v-html="marked(product.data.description)"></p>
                <c-button class="mt-3" @click="$router.push('/customize/' + product?.id)">Start Customizing</c-button>
            </c-grid-item>
            <c-grid-item
                class="width-50-all width-100-sm"
                v-if="product.data.category == 'Gallery Print' && artist?.data"
            >
                <h1>{{ product.data.name }}</h1>
                <span>
                    Artist: <router-link :to="`/artist/${artist.id}`">{{ artist.data.name }}</router-link>
                </span>
                <p class="mb-3" v-html="marked(product.data.description)"></p>
                <c-grid v-if="product.data && product.data.selections" class="widths-100-all">
                    <c-grid-item v-for="selection in product.data.selections" :key="selection.name">
                        <c-select
                            :label="selection.name"
                            :options="selection.options"
                            :display="(option) => option?.name"
                            :showInfo="(option) => option?.description"
                            :map="(option) => option?.name"
                            v-if="print.selections"
                            v-model="print.selections[selection.name]"
                        >
                            <template #info="slotProps">
                                <p v-html="marked(slotProps.option.description)"></p>
                                <c-image
                                    class="mt-2"
                                    v-if="slotProps.option.images[0]"
                                    @click.stop
                                    :zoom="true"
                                    :image="slotProps.option.images[0]"
                                />
                            </template>
                        </c-select>
                    </c-grid-item>
                    <c-grid-item class="width-grow-all">
                        <c-numberbox label="Quantity" v-model="print.quantity" />
                    </c-grid-item>
                    <c-grid-item class="width-shrink-all">
                        <h1 class="mt-4 mb-0" style="font-size: 1.7rem" v-if="print.price">${{ print.price }}</h1>
                    </c-grid-item>

                    <c-grid-item class="flex f-justify-end width-100-all">
                        <c-button @click="addToCart()">Add to Cart</c-button>
                    </c-grid-item>
                </c-grid>
            </c-grid-item>
        </c-grid>
    </c-panel>
    <c-panel v-if="product?.data && product.data.category == 'Custom Print'">
        <h1>Recent Work</h1>
        <c-feed :category="product.data.name" />
    </c-panel>
</template>

<script lang="ts">
import { defineComponent, inject, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { setTitle } from "../router";
import { Database } from "vuebase";
import { Artist, CartItem, Product } from "../main";
import marked from "marked";
import pluralize from "pluralize";
import OrderController from "@/controllers/order-controller";

export default defineComponent({
    name: "Product",
    setup() {
        const productId = useRoute().params.id as string;
        const database = inject(Database.InjectionKey);
        const product = database?.collection<Product>("products").document(productId);
        const artist = ref<Database.Document<Artist>>();
        const print = ref<Partial<CartItem>>({ productId: productId, selections: {}, quantity: 1 });
        const orderController = new OrderController();
        const router = useRouter();

        watch(
            () => product,
            () => {
                if (product?.data?.category == "Custom Print") {
                    setTitle(pluralize(product?.data?.name ?? ""));
                } else if (product?.data?.category == "Gallery Print") {
                    setTitle(product?.data?.name ?? "");
                }

                if (product?.data?.artist && artist.value == undefined) {
                    artist.value = database?.collection<Artist>("artists").document(product?.data?.artist);
                }
            },
            { immediate: true, deep: true },
        );

        watch(
            print,
            () => {
                print.value.price = orderController.calculateItemPrice(print.value);
            },
            { deep: true, immediate: true },
        );

        function addToCart() {
            orderController.addItem(print.value as CartItem);
            router.push("/cart");
        }

        return { database, print, product, marked, pluralize, artist, addToCart };
    },
});
</script>
